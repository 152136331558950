var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-sheet',{staticClass:"pa-2 elevation-4 rounded-t",attrs:{"dark":"","color":"primary"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-select',{attrs:{"items":[
                            {
                                text: 'Course Name', 
                                value: '__courseName',
                            },
                            {
                                text: 'Company Name', 
                                value: '__companyName',
                            } ],"item-value":function (item) { return item; },"light":"","flat":"","solo":"","hide-details":""},model:{value:(_vm.search.field),callback:function ($$v) {_vm.$set(_vm.search, "field", $$v)},expression:"search.field"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"9"}},[_c('v-text-field',{attrs:{"flat":"","solo-inverted":"","hide-details":"","clearable":"","prepend-inner-icon":"mdi-magnify","type":"search","label":("Search Course Requests by " + (_vm.search.field.text))},model:{value:(_vm.search.term),callback:function ($$v) {_vm.$set(_vm.search, "term", $$v)},expression:"search.term"}})],1)],1)],1),_c('v-data-iterator',{attrs:{"items":_vm.courseRequests,"loading":_vm.isLoadingCourseRequests,"server-items-length":_vm.pagination.itemsTotalCount,"sort-by":_vm.pagination.sortBy,"sort-desc":_vm.pagination.sortDesc,"must-sort":"","hide-default-footer":""},on:{"update:sortBy":function($event){return _vm.$set(_vm.pagination, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.pagination, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.pagination, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.pagination, "sortDesc", $event)}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('v-sheet',{staticStyle:{"border":"thin solid rgba(0, 0, 0, 0.12) !important"},attrs:{"color":"white"}},[_c('div',{staticClass:"table-top px-6"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"9"}}),_c('v-col',{staticClass:"d-flex align-center justify-end",attrs:{"cols":"12","md":"3"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"secondary","icon":"","small":"","loading":_vm.isReloadingCourseRequests},on:{"click":function($event){return _vm.reloadCourseRequests()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Reload")])]),_c('div',{staticClass:"caption text-right"},[_vm._v(" showing "+_vm._s(_vm.courseRequests.length)+" of "+_vm._s(_vm.pagination.itemsTotalCount)+" results ")])],1)],1)],1)])]},proxy:true},{key:"default",fn:function(props){return [_c('v-row',_vm._l((3),function(column,columnIndex){return _c('v-col',{key:("column-" + column),attrs:{"cols":"12","sm":"6","md":"4"}},[_vm._l((props.items),function(courseRequest,requestIndex){return [((requestIndex % 3) === columnIndex)?_c('v-card',{key:("course-request-" + (courseRequest.id)),staticClass:"py-2",attrs:{"outlined":"","elevation":"1","color":"white"}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"py-2 px-4"},[_c('v-avatar',{attrs:{"size":"40","tile":""}},[_c('v-img',{attrs:{"src":courseRequest.company.logo.src}})],1)],1),_c('div',{staticClass:"pa-2"},[_c('div',{staticClass:"text-body-1 primary--text"},[_vm._v("Company")]),_c('div',{staticClass:"text-body-2"},[_c('span',[_vm._v(_vm._s(courseRequest.company.name))])])])]),_c('div',{staticClass:"pa-2"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"red","loading":_vm.isRemovingCourseRequests[courseRequest.id]},on:{"click":function($event){return _vm.removeCourseRequest(courseRequest)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}],null,true)},[_c('span',[_vm._v("Remove")])])],1)]),_c('v-divider'),_c('v-card-text',[_c('div',{staticClass:"text-body-1 primary--text"},[_vm._v("Course Name")]),_c('div',[_vm._v(_vm._s(courseRequest.courseName))]),_c('div',{staticClass:"pt-3"},[_c('div',{staticClass:"text-body-1 primary--text"},[_vm._v("Request Details")]),_c('div',[_vm._v(_vm._s(courseRequest.details))])])])],1):_vm._e()]})],2)}),1)]}},((_vm.pagination.itemsTotalCount > _vm.courseRequests.length) && !_vm.isLoadingCourseRequests)?{key:"footer",fn:function(){return [_c('div',{staticClass:"table-bottom px-6"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-around"}},[_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.loadMoreCourseRequests()}}},[_vm._v(" Load More ... ")])],1)])],1)],1)]},proxy:true}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }